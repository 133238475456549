import React, { useState } from 'react';
import {
  Container,
  Typography,
  Box,
  TextField,
  Button,
  Paper,
  Grid,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Alert,
  Chip
} from '@mui/material';
import { AddLocation, Upload } from '@mui/icons-material';

const categories = [
  'Tourist Spot',
  'Restaurant',
  'Resort',
  'Beach',
  'Hiking Trail',
  'Viewpoint',
  'Water Sport',
  'Winter Sport',
  'Shopping',
  'Entertainment'
];

const AddPlace = () => {
  const [formData, setFormData] = useState({
    name: '',
    category: '',
    description: '',
    address: '',
    website: '',
    phone: '',
    email: '',
    openingHours: '',
    priceRange: '',
    facilities: '',
    accessibility: '',
    bestTimeToVisit: '',
    photos: null,
    submitterName: '',
    submitterEmail: '',
    additionalNotes: ''
  });
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    setSubmitted(true);
    // Reset form
    setFormData({
      name: '',
      category: '',
      description: '',
      address: '',
      website: '',
      phone: '',
      email: '',
      openingHours: '',
      priceRange: '',
      facilities: '',
      accessibility: '',
      bestTimeToVisit: '',
      photos: null,
      submitterName: '',
      submitterEmail: '',
      additionalNotes: ''
    });
  };

  return (
    <Box
      sx={{
        py: 8,
        background: 'linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)',
        minHeight: '100vh'
      }}
    >
      <Container maxWidth="lg">
        <Typography
          variant="h2"
          component="h1"
          align="center"
          gutterBottom
          sx={{
            fontWeight: 800,
            mb: 6,
            background: 'linear-gradient(45deg, #244237, #2E7D32)',
            backgroundClip: 'text',
            WebkitBackgroundClip: 'text',
            color: 'transparent',
          }}
        >
          Add a New Place
        </Typography>

        <Paper
          elevation={0}
          sx={{
            p: 4,
            background: 'rgba(255,255,255,0.9)',
            backdropFilter: 'blur(10px)',
            borderRadius: 2,
            border: '1px solid rgba(0,0,0,0.1)'
          }}
        >
          {submitted && (
            <Alert severity="success" sx={{ mb: 3 }}>
              Thank you for submitting a new place! Our team will review it shortly.
            </Alert>
          )}

          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              {/* Basic Information */}
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom sx={{ color: '#244237' }}>
                  Basic Information
                </Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Place Name"
                  required
                  value={formData.name}
                  onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth required>
                  <InputLabel>Category</InputLabel>
                  <Select
                    value={formData.category}
                    label="Category"
                    onChange={(e) => setFormData({ ...formData, category: e.target.value })}
                  >
                    {categories.map((category) => (
                      <MenuItem key={category} value={category}>
                        {category}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Description"
                  multiline
                  rows={4}
                  required
                  value={formData.description}
                  onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                />
              </Grid>

              {/* Location & Contact */}
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom sx={{ color: '#244237', mt: 2 }}>
                  Location & Contact Details
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Address"
                  required
                  value={formData.address}
                  onChange={(e) => setFormData({ ...formData, address: e.target.value })}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  label="Website"
                  value={formData.website}
                  onChange={(e) => setFormData({ ...formData, website: e.target.value })}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  label="Phone"
                  value={formData.phone}
                  onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  label="Email"
                  type="email"
                  value={formData.email}
                  onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                />
              </Grid>

              {/* Additional Details */}
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom sx={{ color: '#244237', mt: 2 }}>
                  Additional Details
                </Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Opening Hours"
                  value={formData.openingHours}
                  onChange={(e) => setFormData({ ...formData, openingHours: e.target.value })}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Price Range"
                  value={formData.priceRange}
                  onChange={(e) => setFormData({ ...formData, priceRange: e.target.value })}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Facilities Available"
                  value={formData.facilities}
                  onChange={(e) => setFormData({ ...formData, facilities: e.target.value })}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Accessibility Information"
                  value={formData.accessibility}
                  onChange={(e) => setFormData({ ...formData, accessibility: e.target.value })}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Best Time to Visit"
                  value={formData.bestTimeToVisit}
                  onChange={(e) => setFormData({ ...formData, bestTimeToVisit: e.target.value })}
                />
              </Grid>

              {/* Photos Upload */}
              <Grid item xs={12}>
                <Button
                  component="label"
                  variant="outlined"
                  startIcon={<Upload />}
                  sx={{ mt: 2 }}
                >
                  Upload Photos
                  <input
                    type="file"
                    hidden
                    multiple
                    accept="image/*"
                    onChange={(e) => setFormData({ ...formData, photos: e.target.files })}
                  />
                </Button>
                {formData.photos && (
                  <Box sx={{ mt: 2 }}>
                    <Chip label={`${formData.photos.length} files selected`} />
                  </Box>
                )}
              </Grid>

              {/* Submitter Information */}
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom sx={{ color: '#244237', mt: 2 }}>
                  Your Information
                </Typography>
              </Grid>

              <Grid item xs={12} md={6}>
               <TextField
                 fullWidth
                 label="Your Name"
                 required
                 value={formData.submitterName}
                 onChange={(e) => setFormData({ ...formData, submitterName: e.target.value })}
               />
             </Grid>

             <Grid item xs={12} md={6}>
               <TextField
                 fullWidth
                 label="Your Email"
                 required
                 type="email"
                 value={formData.submitterEmail}
                 onChange={(e) => setFormData({ ...formData, submitterEmail: e.target.value })}
               />
             </Grid>

             <Grid item xs={12}>
               <TextField
                 fullWidth
                 label="Additional Notes"
                 multiline
                 rows={4}
                 value={formData.additionalNotes}
                 onChange={(e) => setFormData({ ...formData, additionalNotes: e.target.value })}
               />
             </Grid>

             <Grid item xs={12}>
               <Button
                 type="submit"
                 variant="contained"
                 size="large"
                 endIcon={<AddLocation />}
                 sx={{
                   mt: 3,
                   background: 'linear-gradient(45deg, #244237, #2E7D32)',
                   '&:hover': {
                     background: 'linear-gradient(45deg, #2E7D32, #244237)',
                   }
                 }}
               >
                 Submit Place
               </Button>
             </Grid>
           </Grid>
         </form>
       </Paper>
     </Container>
   </Box>
 );
};

export default AddPlace;