import React from 'react';
import {
  Box,
  Container,
  Grid,
  Typography,
  Link,
  IconButton,
  Stack,
  styled
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { 
  Facebook, 
  Twitter, 
  Instagram, 
  YouTube,
  KeyboardArrowRight 
} from '@mui/icons-material';

const StyledFooter = styled(Box)(({ theme }) => ({
  background: 'linear-gradient(45deg, #244237 30%, #2E7D32 90%)',
  color: theme.palette.common.white,
  padding: theme.spacing(8, 0, 4),
  marginTop: 'auto',
  position: 'relative',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '4px',
    background: 'linear-gradient(90deg, #244237, #2E7D32, #244237)',
  },
}));

const FooterLink = styled(RouterLink)(({ theme }) => ({
  color: 'white',
  textDecoration: 'none',
  display: 'flex',
  alignItems: 'center',
  transition: 'all 0.3s ease',
  marginBottom: theme.spacing(1),
  '&:hover': {
    transform: 'translateX(8px)',
    color: '#a5d6a7',
  },
}));

const SocialIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.common.white,
  transition: 'all 0.3s ease',
  margin: theme.spacing(0, 1),
  '&:hover': {
    transform: 'translateY(-4px)',
    background: 'rgba(255, 255, 255, 0.1)',
  },
}));

const Footer = () => {
  return (
    <StyledFooter>
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item xs={12} sm={4}>
            <Typography 
              variant="h5" 
              gutterBottom
              sx={{ 
                fontWeight: 600,
                position: 'relative',
                paddingBottom: 2,
                '&::after': {
                  content: '""',
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  width: '60px',
                  height: '3px',
                  background: 'linear-gradient(90deg, #fff, transparent)',
                }
              }}
            >
              Lake Tahoe Secrets
            </Typography>
            <Typography 
              variant="body2" 
              sx={{ 
                lineHeight: 1.8,
                opacity: 0.9
              }}
            >
              Discover the beauty of Lake Tahoe with our comprehensive tourism guide. 
              Explore hidden gems, popular destinations, and unforgettable experiences 
              around this stunning alpine lake.
            </Typography>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Typography 
              variant="h6" 
              gutterBottom
              sx={{ 
                fontWeight: 600,
                position: 'relative',
                paddingBottom: 2,
                '&::after': {
                  content: '""',
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  width: '40px',
                  height: '3px',
                  background: 'linear-gradient(90deg, #fff, transparent)',
                }
              }}
            >
              Quick Links
            </Typography>
            <Stack spacing={1}>
              <FooterLink to="/">
                <KeyboardArrowRight /> Home
              </FooterLink>
              <FooterLink to="/about">
                <KeyboardArrowRight /> About Us
              </FooterLink>
              <FooterLink to="/contact">
                <KeyboardArrowRight /> Contact
              </FooterLink>
              <FooterLink to="/add-place">
                <KeyboardArrowRight /> Add Place
              </FooterLink>
            </Stack>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Typography 
              variant="h6" 
              gutterBottom
              sx={{ 
                fontWeight: 600,
                position: 'relative',
                paddingBottom: 2,
                '&::after': {
                  content: '""',
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  width: '40px',
                  height: '3px',
                  background: 'linear-gradient(90deg, #fff, transparent)',
                }
              }}
            >
              Connect With Us
            </Typography>
            <Box sx={{ mt: 2 }}>
              <SocialIconButton aria-label="Facebook">
                <Facebook />
              </SocialIconButton>
              <SocialIconButton aria-label="Twitter">
                <Twitter />
              </SocialIconButton>
              <SocialIconButton aria-label="Instagram">
                <Instagram />
              </SocialIconButton>
              <SocialIconButton aria-label="YouTube">
                <YouTube />
              </SocialIconButton>
            </Box>
          </Grid>
        </Grid>

        <Box 
          mt={6} 
          pt={3} 
          sx={{ 
            borderTop: '1px solid rgba(255,255,255,0.1)',
          }}
        >
          <Typography 
            variant="body2" 
            align="center"
            sx={{ opacity: 0.8 }}
          >
            © {new Date().getFullYear()} Lake Tahoe Secrets. All rights reserved.
          </Typography>
        </Box>
      </Container>
    </StyledFooter>
  );
};

export default Footer;