import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider, createTheme, CssBaseline, Box } from '@mui/material';
import Header from './components/Header.js';
import Footer from './components/Footer.js';
import HomePage from './components/HomePage.js';
import LocationPage from './components/LocationPage.js';
import locationsData from './data/locations.json';
import ShivasamudraPage from './components/ShivasamudraPage.js';
import About from '../src/pages/About.js';
import Contact from '../src/pages/Contact.js';
import AddPlace from './pages/AddPlace.js';


const theme = createTheme({
  palette: {
    primary: {
      main: '#244237',
    },
    background: {
      default: '#f5f5f5',
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Box display="flex" flexDirection="column" minHeight="100vh">
          <Header />
          <Box component="main" flexGrow={1}>
            <Routes>
            <Route path="/" element={<HomePage />} />               <Route path="/shivanasamudra" element={<ShivasamudraPage />} />   
              <Route path="/" element={<HomePage />} />
              {locationsData.map((location) => (
                <Route 
                  key={location.name}
                  path={`/${location.name.toLowerCase().replace(/\s+/g, '-')}`}
                  element={<LocationPage spotData={location} />}
                />
              ))}
              <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/add-place" element={<AddPlace />} />
            </Routes>
          </Box>
          <Footer />
        </Box>
      </Router>
    </ThemeProvider>
  );
}

export default App;