import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
  Container,
  Typography,
  Box,
  Card,
  CardContent,
  Grid,
  Tabs,
  Tab,
  IconButton,
  styled,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Divider,
  useTheme,
  useMediaQuery,
  Fade,
  Zoom,
  createTheme,
  ThemeProvider
} from '@mui/material';
import {
  LocationOn,
  AttachMoney,
  Cloud,
  DirectionsCar,
  AccessTime,
  WbSunny,
  PhotoCamera,
  Place,
  Event,
  ChildCare,
  HourglassEmpty,
  Attractions,
  DirectionsBus,
  Train,
  Flight,
  Favorite,
  Groups,
  Group,
  Schedule,
  Info
} from '@mui/icons-material';

// Custom theme configuration
const theme = createTheme({
  typography: {
    fontFamily: "'Roboto', 'Arial', sans-serif",
    h1: { fontFamily: "'Playfair Display', serif" },
    h2: { fontFamily: "'Playfair Display', serif" },
    h6: { fontFamily: "'Poppins', sans-serif" },
  },
  palette: {
    primary: {
      main: '#1976d2',
      light: '#42a5f5',
    },
    secondary: {
      main: '#f50057',
    },
    background: {
      default: '#f8faff',
    },
  },
  components: {
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          borderRadius: 12,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 12,
        },
      },
    },
  },
});

// Styled Components
const GlassCard = styled(Card)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.15)',
  backdropFilter: 'blur(10px)',
  borderRadius: '16px',
  boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)',
  border: '1px solid rgba(255, 255, 255, 0.2)',
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0 12px 40px rgba(0, 0, 0, 0.2)',
  },
}));

const HeroImage = styled(Box)(({ theme }) => ({
  position: 'relative',
  height: '70vh',
  width: '100%',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundAttachment: 'fixed',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'linear-gradient(to bottom, rgba(0,0,0,0.3), rgba(0,0,0,0.7))',
  },
  [theme.breakpoints.down('sm')]: {
    height: '50vh',
    backgroundAttachment: 'scroll',
  },
}));

const InfoOverlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  padding: theme.spacing(4),
  background: 'linear-gradient(to top, rgba(0,0,0,0.9), transparent)',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}));

const AnimatedInfoCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  height: '100%',
  transition: 'all 0.3s ease-in-out',
  backgroundColor: theme.palette.background.paper,
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: theme.shadows[8],
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  minHeight: 72,
  padding: '12px',
  color: theme.palette.common.white,
  fontWeight: 600,
  fontSize: '0.9rem',
  textTransform: 'none',
  '&.Mui-selected': {
    color: theme.palette.primary.main,
    backgroundColor: 'white',
  },
  [theme.breakpoints.down('sm')]: {
    minWidth: 'auto',
    padding: '8px',
    minHeight: 60,
    fontSize: '0.8rem',
  },
}));

// Custom scroll hook
const useScrollAnimation = () => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 100);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return scrolled;
};
const LocationPage = ({ spotData }) => {
  const [activeTab, setActiveTab] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const scrolled = useScrollAnimation();

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const InfoCard = ({ icon, title, content, delay = 0 }) => (
    <Zoom in style={{ transitionDelay: `${delay}ms` }}>
      <AnimatedInfoCard elevation={scrolled ? 6 : 3}>
        <Box sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center', 
          textAlign: 'center',
          height: '100%' 
        }}>
          <IconButton
            size={isMobile ? "medium" : "large"}
            color="primary"
            sx={{
              mb: 2,
              background: `${theme.palette.primary.light}20`,
              '&:hover': {
                background: `${theme.palette.primary.light}40`,
                transform: 'rotate(360deg)',
                transition: 'all 0.5s ease-in-out',
              },
            }}
          >
            {icon}
          </IconButton>
          <Typography
            variant={isMobile ? "subtitle1" : "h6"}
            fontWeight="bold"
            color="primary"
            gutterBottom
            sx={{ fontFamily: "'Poppins', sans-serif" }}
          >
            {title}
          </Typography>
          <Typography
            variant="body1"
            color="text.primary"
            sx={{ 
              fontFamily: "'Roboto', sans-serif",
              flex: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            {content}
          </Typography>
        </Box>
      </AnimatedInfoCard>
    </Zoom>
  );

  const TabContent = () => {
    switch(activeTab) {
      case 0: // INFO
        return (
          <Fade in timeout={500}>
            <Box>
              <Grid container spacing={3} sx={{ mb: 4 }}>
                {[
                  { icon: <Event />, title: "Best Time to Visit", content: spotData.bestTime, delay: 100 },
                  { icon: <ChildCare />, title: "Kids Friendly", content: spotData.kidsFriendly, delay: 200 },
                  { icon: <HourglassEmpty />, title: "Time to Spend", content: spotData.timeToSpend, delay: 300 },
                  { icon: <Attractions />, title: "Nearest Attraction", content: spotData.nearestAttraction, delay: 400 }
                ].map((item, index) => (
                  <Grid item xs={12} sm={6} md={3} key={index}>
                    <InfoCard {...item} />
                  </Grid>
                ))}
              </Grid>
              <Typography 
                variant="body1" 
                paragraph 
                color="text.primary" 
                sx={{ 
                  textIndent: '1em',
                  lineHeight: 1.8,
                  fontSize: '1.1rem',
                  textAlign: 'justify',
                  padding: { xs: 1, md: 3 }
                }}
              >
                {spotData.description}
              </Typography>
            </Box>
          </Fade>
        );

      case 1: // How to Reach
        return (
          <Fade in timeout={500}>
            <Grid container spacing={3}>
              {Object.entries(spotData.howToReach).map(([mode, description], index) => (
                <Grid item xs={12} sm={6} key={index}>
                  <Zoom in style={{ transitionDelay: `${index * 100}ms` }}>
                    <Paper 
                      elevation={3} 
                      sx={{ 
                        p: { xs: 2, md: 3 }, 
                        height: '100%',
                        transition: 'all 0.3s ease-in-out',
                        '&:hover': {
                          transform: 'translateY(-5px)',
                          boxShadow: theme.shadows[8],
                        }
                      }}
                    >
                      <Box display="flex" alignItems="center" mb={2}>
                        <IconButton 
                          size={isMobile ? "medium" : "large"} 
                          color="primary" 
                          sx={{ 
                            mr: 1,
                            background: `${theme.palette.primary.light}20`,
                          }}
                        >
                          {mode === 'bus' && <DirectionsBus />}
                          {mode === 'car' && <DirectionsCar />}
                          {mode === 'publicTransport' && <Train />}
                          {mode === 'airport' && <Flight />}
                        </IconButton>
                        <Typography 
                          variant={isMobile ? "subtitle1" : "h6"} 
                          fontWeight="bold" 
                          color="primary"
                        >
                          By {mode.charAt(0).toUpperCase() + mode.slice(1)}
                        </Typography>
                      </Box>
                      <Typography 
                        variant="body1" 
                        color="text.primary" 
                        sx={{ 
                          lineHeight: 1.8,
                          fontSize: '1rem'
                        }}
                      >
                        {description}
                      </Typography>
                    </Paper>
                  </Zoom>
                </Grid>
              ))}
            </Grid>
          </Fade>
        );

      case 2: // Timings
        return (
          <Fade in timeout={500}>
            <Grid container spacing={3}>
              {Object.entries(spotData.timings).map(([type, timing], index) => (
                <Grid item xs={12} sm={6} key={index}>
                  <Zoom in style={{ transitionDelay: `${index * 100}ms` }}>
                    <Paper 
                      elevation={3} 
                      sx={{ 
                        p: { xs: 2, md: 3 }, 
                        height: '100%',
                        transition: 'all 0.3s ease-in-out',
                        '&:hover': {
                          transform: 'translateY(-5px)',
                          boxShadow: theme.shadows[8],
                        }
                      }}
                    >
                      <Box display="flex" alignItems="center" mb={2}>
                        <IconButton 
                          size={isMobile ? "medium" : "large"} 
                          color="primary" 
                          sx={{ 
                            mr: 1,
                            background: `${theme.palette.primary.light}20`,
                          }}
                        >
                          <Schedule />
                        </IconButton>
                        <Typography 
                          variant={isMobile ? "subtitle1" : "h6"} 
                          fontWeight="bold" 
                          color="primary"
                        >
                          {type.charAt(0).toUpperCase() + type.slice(1)} Timings
                        </Typography>
                      </Box>
                      <Typography 
                        variant="body1" 
                        color="text.primary" 
                        sx={{ lineHeight: 1.8 }}
                      >
                        {timing}
                      </Typography>
                    </Paper>
                  </Zoom>
                </Grid>
              ))}
            </Grid>
          </Fade>
        );
        case 3: // Weather
        return (
          <Fade in timeout={500}>
            <Box>
              <Typography 
                variant="h6" 
                gutterBottom 
                color="primary" 
                sx={{ 
                  mb: 3,
                  fontWeight: 600,
                  textAlign: { xs: 'center', md: 'left' }
                }}
              >
                Monthly Weather Information
              </Typography>
              <Grid container spacing={3}>
                {spotData.weatherInfo.map((info, index) => (
                  <Grid item xs={12} sm={6} md={3} key={index}>
                    <Zoom in style={{ transitionDelay: `${index * 100}ms` }}>
                      <Paper 
                        elevation={3} 
                        sx={{ 
                          p: { xs: 2, md: 3 },
                          height: '100%',
                          transition: 'all 0.3s ease-in-out',
                          '&:hover': {
                            transform: 'translateY(-5px)',
                            boxShadow: theme.shadows[8],
                          }
                        }}
                      >
                        <Box sx={{ textAlign: 'center' }}>
                          <Typography 
                            variant="h6" 
                            fontWeight="bold" 
                            color="primary"
                            gutterBottom
                          >
                            {info.month}
                          </Typography>
                          <Box 
                            sx={{ 
                              display: 'inline-flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              p: 1,
                              mb: 1,
                              borderRadius: '50%',
                              backgroundColor: `${theme.palette.primary.light}20`,
                            }}
                          >
                            <WbSunny color="primary" />
                          </Box>
                          <Typography 
                            variant="h5" 
                            color="text.primary" 
                            gutterBottom
                            sx={{ fontWeight: 600 }}
                          >
                            {info.temp}
                          </Typography>
                          <Typography 
                            variant="body2" 
                            color="text.secondary"
                            sx={{ lineHeight: 1.6 }}
                          >
                            {info.description}
                          </Typography>
                        </Box>
                      </Paper>
                    </Zoom>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Fade>
        );

      case 4: // Things to Do
        return (
          <Fade in timeout={500}>
            <Box>
              {Object.entries(spotData.thingsToDo).map(([category, activities], index) => (
                <Box key={category} sx={{ mb: 4 }}>
                  <Typography 
                    variant="h6" 
                    gutterBottom 
                    color="primary" 
                    sx={{ 
                      display: 'flex', 
                      alignItems: 'center',
                      mb: 2,
                      fontWeight: 600 
                    }}
                  >
                    {category === 'couple' && <Favorite sx={{ mr: 1 }} />}
                    {category === 'kids' && <ChildCare sx={{ mr: 1 }} />}
                    {category === 'family' && <Groups sx={{ mr: 1 }} />}
                    {`Things to do for ${category}`}
                  </Typography>
                  <Zoom in style={{ transitionDelay: `${index * 100}ms` }}>
                    <Paper 
                      elevation={3} 
                      sx={{ 
                        p: { xs: 2, md: 3 },
                        transition: 'all 0.3s ease-in-out',
                        '&:hover': {
                          transform: 'translateY(-5px)',
                          boxShadow: theme.shadows[8],
                        }
                      }}
                    >
                      <List>
                        {activities.map((activity, idx) => (
                          <ListItem 
                            key={idx}
                            sx={{
                              transition: 'all 0.2s ease-in-out',
                              '&:hover': {
                                backgroundColor: `${theme.palette.primary.light}10`,
                                transform: 'translateX(10px)',
                              }
                            }}
                          >
                            <ListItemIcon>
                              <IconButton 
                                size="small" 
                                color="primary"
                                sx={{ 
                                  backgroundColor: `${theme.palette.primary.light}20`,
                                }}
                              >
                                <Group />
                              </IconButton>
                            </ListItemIcon>
                            <ListItemText 
                              primary={activity} 
                              primaryTypographyProps={{ 
                                color: 'text.primary',
                                sx: { 
                                  fontWeight: 500,
                                  fontSize: '1rem'
                                }
                              }}
                            />
                          </ListItem>
                        ))}
                      </List>
                    </Paper>
                  </Zoom>
                </Box>
              ))}
            </Box>
          </Fade>
        );

      case 5: // Nearby Spots
        return (
          <Fade in timeout={500}>
            <Grid container spacing={3}>
              {spotData.nearbySpots.map((spot, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Zoom in style={{ transitionDelay: `${index * 100}ms` }}>
                    <Paper 
                      elevation={3} 
                      sx={{ 
                        p: { xs: 2, md: 3 }, 
                        height: '100%',
                        transition: 'all 0.3s ease-in-out',
                        '&:hover': {
                          transform: 'translateY(-5px)',
                          boxShadow: theme.shadows[8],
                        }
                      }}
                    >
                      <Typography 
                        variant="h6" 
                        gutterBottom 
                        color="primary"
                        sx={{ fontWeight: 600 }}
                      >
                        {spot.name}
                      </Typography>
                      <Box 
                        sx={{ 
                          display: 'flex',
                          gap: 1,
                          mb: 2
                        }}
                      >
                        <Typography 
                          variant="body2" 
                          color="text.secondary"
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 0.5
                          }}
                        >
                          <LocationOn fontSize="small" />
                          Distance: {spot.distance}
                        </Typography>
                        <Divider orientation="vertical" flexItem />
                        <Typography 
                          variant="body2" 
                          color="text.secondary"
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 0.5
                          }}
                        >
                          <DirectionsCar fontSize="small" />
                          {spot.howToGo}
                        </Typography>
                      </Box>
                      <Divider sx={{ my: 1 }} />
                      <Typography 
                        variant="body2" 
                        color="text.primary"
                        sx={{ lineHeight: 1.8 }}
                      >
                        {spot.description}
                      </Typography>
                    </Paper>
                  </Zoom>
                </Grid>
              ))}
            </Grid>
          </Fade>
        );

      default:
        return null;
    }
  };
  return (
    <>
      <Helmet>
  <title>{`${spotData?.name || ''} Lake Tahoe: Things to Do, Weather, Entry Fee & More`}</title>
  <meta 
    name="description" 
    content={`Explore ${spotData?.name || ''} at Lake Tahoe. ${spotData?.weather || ''}. Best time to visit: ${spotData?.bestTime || ''}. Discover local attractions, ${spotData?.thingsToDo?.family?.[0] || 'family activities'}, and get essential visitor information.`} 
  />
  <link 
 rel="canonical" 
 href={`https://laketahoesecrets.com/${spotData?.name?.toLowerCase().replace(/\s+/g, '-')}`} 
/>
</Helmet>
    <ThemeProvider theme={theme}>
      <Box sx={{ bgcolor: 'background.default', minHeight: '100vh' }}>
        <HeroImage style={{ backgroundImage: `url(${spotData.imageUrl})` }}>
          <InfoOverlay>
            <Container maxWidth="lg">
              <Fade in timeout={1000}>
                <Typography
                  variant={isMobile ? "h3" : "h2"}
                  fontWeight="bold"
                  color="white"
                  mb={3}
                  sx={{
                    fontFamily: "'Playfair Display', serif",
                    textShadow: '2px 2px 4px rgba(0,0,0,0.3)',
                    fontSize: { xs: '2rem', sm: '2.5rem', md: '3.5rem' },
                    textAlign: { xs: 'center', md: 'left' }
                  }}
                >
                  {spotData.name}
                </Typography>
              </Fade>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <Fade in timeout={1200}>
                    <Box>
                      <GlassCard>
                        <CardContent sx={{ 
                          display: 'flex', 
                          flexDirection: 'column', 
                          alignItems: 'center', 
                          textAlign: 'center',
                          gap: 1
                        }}>
                          <IconButton 
                            size="large" 
                            sx={{ 
                              color: 'white',
                              backgroundColor: 'rgba(255, 255, 255, 0.1)',
                              '&:hover': {
                                backgroundColor: 'rgba(255, 255, 255, 0.2)',
                              }
                            }}
                          >
                            <LocationOn />
                          </IconButton>
                          <Typography 
                            variant="h6" 
                            fontWeight="bold" 
                            color="white"
                            sx={{ 
                              fontSize: { xs: '1.1rem', md: '1.25rem' } 
                            }}
                          >
                            Location
                          </Typography>
                          <Typography 
                            variant="body1" 
                            color="white"
                            sx={{ 
                              opacity: 0.9,
                              fontSize: { xs: '0.9rem', md: '1rem' }
                            }}
                          >
                            {spotData.location}
                          </Typography>
                        </CardContent>
                      </GlassCard>
                    </Box>
                  </Fade>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Fade in timeout={1400}>
                    <Box>
                      <GlassCard>
                        <CardContent sx={{ 
                          display: 'flex', 
                          flexDirection: 'column', 
                          alignItems: 'center', 
                          textAlign: 'center',
                          gap: 1
                        }}>
                          <IconButton 
                            size="large" 
                            sx={{ 
                              color: 'white',
                              backgroundColor: 'rgba(255, 255, 255, 0.1)',
                              '&:hover': {
                                backgroundColor: 'rgba(255, 255, 255, 0.2)',
                              }
                            }}
                          >
                            <AttachMoney />
                          </IconButton>
                          <Typography 
                            variant="h6" 
                            fontWeight="bold" 
                            color="white"
                            sx={{ 
                              fontSize: { xs: '1.1rem', md: '1.25rem' } 
                            }}
                          >
                            Entry Fee
                          </Typography>
                          <Box>
                            <Typography 
                              variant="body1" 
                              color="white"
                              sx={{ 
                                opacity: 0.9,
                                fontSize: { xs: '0.9rem', md: '1rem' }
                              }}
                            >
                              Adults: {spotData.entryFee.adults}
                            </Typography>
                            <Typography 
                              variant="body1" 
                              color="white"
                              sx={{ 
                                opacity: 0.9,
                                fontSize: { xs: '0.9rem', md: '1rem' }
                              }}
                            >
                              Children: {spotData.entryFee.children}
                            </Typography>
                          </Box>
                        </CardContent>
                      </GlassCard>
                    </Box>
                  </Fade>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Fade in timeout={1600}>
                    <Box>
                      <GlassCard>
                        <CardContent sx={{ 
                          display: 'flex', 
                          flexDirection: 'column', 
                          alignItems: 'center', 
                          textAlign: 'center',
                          gap: 1
                        }}>
                          <IconButton 
                            size="large" 
                            sx={{ 
                              color: 'white',
                              backgroundColor: 'rgba(255, 255, 255, 0.1)',
                              '&:hover': {
                                backgroundColor: 'rgba(255, 255, 255, 0.2)',
                              }
                            }}
                          >
                            <Cloud />
                          </IconButton>
                          <Typography 
                            variant="h6" 
                            fontWeight="bold" 
                            color="white"
                            sx={{ 
                              fontSize: { xs: '1.1rem', md: '1.25rem' } 
                            }}
                          >
                            Weather
                          </Typography>
                          <Typography 
                            variant="body1" 
                            color="white"
                            sx={{ 
                              opacity: 0.9,
                              fontSize: { xs: '0.9rem', md: '1rem' }
                            }}
                          >
                            {spotData.weather}
                          </Typography>
                        </CardContent>
                      </GlassCard>
                    </Box>
                  </Fade>
                </Grid>
              </Grid>
            </Container>
          </InfoOverlay>
        </HeroImage>

        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Paper 
            elevation={3} 
            sx={{ 
              borderRadius: '16px', 
              overflow: 'hidden',
              boxShadow: '0 4px 20px rgba(0,0,0,0.1)'
            }}
          >
            <Tabs 
              value={activeTab} 
              onChange={handleTabChange} 
              aria-label="spot information tabs"
              sx={{ 
                bgcolor: 'primary.main', 
                borderRadius: '16px 16px 0 0',
                '& .MuiTabs-indicator': {
                  backgroundColor: 'white',
                  height: 3
                }
              }}
              variant={isMobile ? "scrollable" : "fullWidth"}
              scrollButtons={isMobile}
              allowScrollButtonsMobile
            >
              <StyledTab label="Info" icon={<Info />} />
              <StyledTab label="How to Reach" icon={<DirectionsCar />} />
              <StyledTab label="Timings" icon={<AccessTime />} />
              <StyledTab label="Weather" icon={<WbSunny />} />
              <StyledTab label="Things to do" icon={<PhotoCamera />} />
              <StyledTab label="Nearby Spots" icon={<Place />} />
            </Tabs>
            <Box sx={{ 
              p: { xs: 2, md: 3 }, 
              bgcolor: 'background.paper',
              minHeight: '50vh'
            }}>
              <TabContent />
            </Box>
          </Paper>
        </Container>
      </Box>
    </ThemeProvider>
    </>
  );
};

export default LocationPage;