import React from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Container, 
  Grid, 
  Card, 
  Typography, 
  Box,
  Stack
} from '@mui/material';
import { 
  CreditCard, 
  LocationOn,
  Thermostat
} from '@mui/icons-material';
import spotsData from '../data/locations.json';

const TouristSpotCard = ({ spot }) => {
  const navigate = useNavigate();
  
  const handleClick = () => {
    const spotUrl = spot.name.toLowerCase().replace(/\s+/g, '-');
    navigate(`/${spotUrl}`);
  };

  return (
    <Card 
      sx={{ 
        position: 'relative',
        height: 400,
        cursor: 'pointer',
        overflow: 'hidden',
        borderRadius: 2,
        '&:hover': {
          transform: 'scale(1.02)',
          transition: 'all 0.3s ease-in-out',
        }
      }}
      onClick={handleClick}
    >
      {/* Background Image */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundImage: `url(${spot.imageUrl})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      />

      {/* Glass Effect Overlay */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: 'linear-gradient(to bottom, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0.7) 100%)',
          backdropFilter: 'blur(8px)',
          transition: 'all 0.3s ease-in-out',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          p: 3,
        }}
      >
        {/* Location Name */}
        <Typography 
          variant="h4" 
          sx={{ 
            color: 'white',
            fontWeight: 800,
            textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
            mb: 2
          }}
        >
          {spot.name}
        </Typography>

        {/* Info Grid */}
        <Stack spacing={2}>
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center',
            backgroundColor: 'rgba(255,255,255,0.1)',
            backdropFilter: 'blur(10px)',
            p: 1.5,
            borderRadius: 1
          }}>
            <LocationOn sx={{ color: 'white', mr: 1 }} />
            <Typography variant="body2" sx={{ color: 'white' }}>
              {spot.location}
            </Typography>
          </Box>

          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center',
            backgroundColor: 'rgba(255,255,255,0.1)',
            backdropFilter: 'blur(10px)',
            p: 1.5,
            borderRadius: 1
          }}>
            <Thermostat sx={{ color: 'white', mr: 1 }} />
            <Typography variant="body2" sx={{ color: 'white' }}>
              {spot.weather}
            </Typography>
          </Box>

          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center',
            backgroundColor: 'rgba(255,255,255,0.1)',
            backdropFilter: 'blur(10px)',
            p: 1.5,
            borderRadius: 1
          }}>
            <CreditCard sx={{ color: 'white', mr: 1 }} />
            <Typography variant="body2" sx={{ color: 'white' }}>
              Adults: {spot.entryFee.adults}, Children: {spot.entryFee.children}
            </Typography>
          </Box>
        </Stack>
      </Box>
    </Card>
  );
};

const HomePage = () => {
  return (
    <Container maxWidth="xl" sx={{ py: 4 }}>
      <Typography 
        variant="h3" 
        component="h1" 
        gutterBottom 
        align="center"
        sx={{ 
          mb: 4,
          fontWeight: 'bold',
          color: '#1a237e'
        }}
      >
        Lake Tahoe Tourist Spots
      </Typography>

      <Grid container spacing={3}>
        {spotsData.map((spot, index) => (
          <Grid item xs={12} md={6} lg={4} key={index}>
            <TouristSpotCard spot={spot} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default HomePage;