import React from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  Container,
  styled,
  Button
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { Add, InfoOutlined, MailOutline } from '@mui/icons-material';

// Styled components
const StyledAppBar = styled(AppBar)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.95)',
  backdropFilter: 'blur(8px)',
  boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
  borderBottom: '1px solid rgba(255, 255, 255, 0.3)',
}));

const LogoLink = styled(RouterLink)({
  textDecoration: 'none',
  display: 'flex',
  alignItems: 'center',
  transition: 'transform 0.3s ease',
  '&:hover': {
    transform: 'scale(1.02)',
  },
});

const NavButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(0, 1),
  padding: theme.spacing(1, 2),
  borderRadius: '50px',
  textTransform: 'none',
  fontSize: '0.9rem',
  fontWeight: 500,
  color: '#244237',
  position: 'relative',
  overflow: 'hidden',
  background: 'transparent',
  transition: 'all 0.3s ease',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'linear-gradient(45deg, #244237, #2E7D32)',
    opacity: 0,
    transition: 'opacity 0.3s ease',
    zIndex: -1,
  },
  '&:hover': {
    color: '#fff',
    '&::before': {
      opacity: 1,
    },
  },
}));

const AddPlaceButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(0, 1),
  padding: theme.spacing(1, 3),
  borderRadius: '50px',
  textTransform: 'none',
  fontSize: '0.9rem',
  fontWeight: 600,
  color: '#fff',
  background: 'linear-gradient(45deg, #244237, #2E7D32)',
  boxShadow: '0 4px 15px rgba(46, 125, 50, 0.2)',
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 6px 20px rgba(46, 125, 50, 0.3)',
  },
}));

const Header = () => {
  return (
    <StyledAppBar position="sticky">
      <Container maxWidth="xl">
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            py: 1,
          }}
        >
          {/* Logo */}
          <LogoLink to="/">
            <Typography
              variant="h5"
              sx={{
                fontWeight: 800,
                letterSpacing: '0.1rem',
                background: 'linear-gradient(45deg, #244237, #2E7D32)',
                backgroundClip: 'text',
                WebkitBackgroundClip: 'text',
                color: 'transparent',
                textShadow: '2px 2px 4px rgba(0,0,0,0.1)',
              }}
            >
              LAKE TAHOE SECRETS
            </Typography>
          </LogoLink>

          {/* Navigation Buttons */}
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <NavButton
              component={RouterLink}
              to="/about"
              startIcon={<InfoOutlined />}
            >
              About
            </NavButton>
            <NavButton
              component={RouterLink}
              to="/contact"
              startIcon={<MailOutline />}
            >
              Contact
            </NavButton>
            <AddPlaceButton
              component={RouterLink}
              to="/add-place"
              startIcon={<Add />}
            >
              Add Place
            </AddPlaceButton>
          </Box>
        </Toolbar>
      </Container>
    </StyledAppBar>
  );
};

export default Header;