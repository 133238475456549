import React from 'react';
import { Container, Typography, Box, Paper, Grid } from '@mui/material';
import { ExploreOutlined, EmojiNatureOutlined, HistoryEdu } from '@mui/icons-material';

const AboutSection = ({ icon, title, content }) => (
  <Paper 
    elevation={0}
    sx={{
      p: 4,
      height: '100%',
      background: 'rgba(255,255,255,0.9)',
      backdropFilter: 'blur(10px)',
      borderRadius: 2,
      border: '1px solid rgba(0,0,0,0.1)'
    }}
  >
    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
      {icon}
      <Typography variant="h5" component="h2" sx={{ ml: 2, fontWeight: 600 }}>
        {title}
      </Typography>
    </Box>
    <Typography variant="body1" color="text.secondary" sx={{ lineHeight: 1.7 }}>
      {content}
    </Typography>
  </Paper>
);

const About = () => {
  return (
    <Box
      sx={{
        py: 8,
        background: 'linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)',
        minHeight: '100vh'
      }}
    >
      <Container maxWidth="lg">
        <Typography
          variant="h2"
          component="h1"
          align="center"
          gutterBottom
          sx={{
            fontWeight: 800,
            mb: 6,
            background: 'linear-gradient(45deg, #244237, #2E7D32)',
            backgroundClip: 'text',
            WebkitBackgroundClip: 'text',
            color: 'transparent',
          }}
        >
          About Lake Tahoe Secrets
        </Typography>

        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <AboutSection
              icon={<ExploreOutlined sx={{ fontSize: 40, color: '#244237' }} />}
              title="Our Mission"
              content="Lake Tahoe Secrets is dedicated to uncovering the hidden gems and 
              lesser-known attractions around Lake Tahoe. Our mission is to help 
              visitors discover authentic experiences beyond the typical tourist 
              spots, while promoting responsible tourism and environmental conservation."
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <AboutSection
              icon={<EmojiNatureOutlined sx={{ fontSize: 40, color: '#244237' }} />}
              title="Our Commitment"
              content="We are committed to providing accurate, up-to-date information 
              about Lake Tahoe's diverse attractions. Our team personally visits 
              and verifies each location, ensuring that our recommendations 
              maintain the balance between tourism accessibility and environmental 
              preservation."
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <AboutSection
              icon={<HistoryEdu sx={{ fontSize: 40, color: '#244237' }} />}
              title="Our Story"
              content="Founded by local Lake Tahoe enthusiasts, our platform emerged 
              from a passion for sharing the area's natural beauty and cultural 
              heritage. We collaborate with local businesses, environmental 
              organizations, and community members to provide comprehensive 
              guides for visitors."
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default About;