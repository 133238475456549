import React, { useState } from 'react';
import { 
  ThemeProvider, createTheme, responsiveFontSizes,
  Container, Typography, Box, Card, CardContent, 
  Grid, IconButton, styled, List, ListItem, ListItemIcon, ListItemText,
  Paper, Button, Accordion, AccordionSummary, AccordionDetails,
  Tabs, Tab, ImageList, ImageListItem, Rating, Avatar, Chip,
  useMediaQuery, Skeleton
} from '@mui/material';
import {
  LocationOn, AttachMoney, WbSunny, ExpandMore,
  DirectionsCar, AccessTime, Event, ChildCare, 
  HourglassEmpty, Attractions, DirectionsBus, 
  Train, Flight, Favorite, Groups, Group,
  Schedule, Warning, Visibility, AccountBalance,
  KeyboardArrowDown, PhotoCamera, Star
} from '@mui/icons-material';
import { keyframes } from '@emotion/react';

// Create and customize the theme
let theme = createTheme({
  palette: {
    primary: {
      main: '#2E7D32', // Forest Green
    },
    secondary: {
      main: '#1565C0', // Deep Blue
    },
    background: {
      default: '#F1F8E9', // Light Nature Green
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontWeight: 700,
    },
    h2: {
      fontWeight: 600,
    },
  },
});

theme = responsiveFontSizes(theme);

// Animations
const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const bounceAnimation = keyframes`
  0%, 20%, 50%, 80%, 100% { transform: translateY(0); }
  40% { transform: translateY(-30px); }
  60% { transform: translateY(-15px); }
`;

// Styled components
const HeroSection = styled(Box)(({ theme }) => ({
  position: 'relative',
  height: '100vh',
  width: '100%',
  backgroundImage: `url(https://i0.wp.com/trailsofinju.com/wp-content/uploads/2020/05/Barachukki-Waterfalls-Shivanasamudra.jpeg?fit=1225%2C817&ssl=1)`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  padding: theme.spacing(3),
  color: 'white',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'linear-gradient(to bottom, rgba(0,0,0,0.2), rgba(0,0,0,0.8))',
  },
}));

const ScrollIndicator = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  bottom: theme.spacing(4),
  left: '50%',
  transform: 'translateX(-50%)',
  color: 'white',
  animation: `${bounceAnimation} 2s infinite`,
}));

const Section = styled(Box)(({ theme }) => ({
  padding: theme.spacing(6, 2),
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(8, 4),
  },
}));

const InfoChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5),
  backgroundColor: 'rgba(255, 255, 255, 0.2)',
  color: 'white',
  '& .MuiChip-icon': {
    color: 'white',
  },
}));

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: theme.shadows[8],
  },
}));

const AnimatedTypography = styled(Typography)`
  animation: ${fadeIn} 1s ease-out;
`;

const ImageGallery = styled(ImageList)(({ theme }) => ({
  '& .MuiImageListItem-root': {
    overflow: 'hidden',
    '& img': {
      transition: 'transform 0.3s ease-in-out',
      '&:hover': {
        transform: 'scale(1.1)',
      },
    },
  },
}));

const ShivanasamudraPage = () => {
  const [expandedAccordion, setExpandedAccordion] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedAccordion(isExpanded ? panel : false);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const spotData = {
    name: "Shivanasamudra Waterfalls",
    location: "Mandya district, Karnataka",
    entryFee: "₹20 for adults, ₹10 for children",
    weather: "28°C, Partly Cloudy",
    bestTime: "October to February",
    kidsFriendly: "Yes",
    timeToSpend: "2-3 hours",
    nearestAttraction: "Talakadu (30 km)",
    description: "Shivanasamudra Waterfalls, located in the Mandya district of Karnataka, is a natural wonder that captivates visitors with its majestic beauty. The falls are formed by the Kaveri River as it plunges from the Deccan Plateau, creating two main segments: Gaganachukki and Bharachukki.",
    fullDescription: "Shivanasamudra Waterfalls, located in the Mandya district of Karnataka, is a natural wonder that captivates visitors with its majestic beauty. The falls are formed by the Kaveri River as it plunges from the Deccan Plateau, creating two main segments: Gaganachukki and Bharachukki. The Gaganachukki falls, with its thunderous roar and misty spray, offers a spectacular sight as water cascades down rocky cliffs. Visitors can view this segment from designated viewpoints, providing breathtaking panoramas of the falls and surrounding landscape. Bharachukki, the wider segment, presents a more gentle cascade, allowing visitors to get closer to the water. The area around Bharachukki is popular for picnics and offers opportunities for shallow water play during safer seasons.",
    howToReach: {
      bus: "Regular buses operate from Bangalore's Majestic Bus Stand to Kollegal, the nearest town to Shivanasamudra. From Kollegal, local buses or taxis are available to reach the falls.",
      car: "Driving from Bangalore to Shivanasamudra is a popular option. Take NH 275 towards Mysore, then turn right at Maddur onto SH 85 towards Malavalli. From Malavalli, follow signs to Shivanasamudra.",
      publicTransport: "Take a train from Bangalore to Maddur railway station, then catch a local bus to Shivanasamudra.",
      air: "The nearest airport is Kempegowda International Airport in Bangalore, about 180 km from Shivanasamudra. After reaching Bangalore, you can hire a taxi or take a bus to reach the falls."
    },
    timings: {
      general: "6:00 AM to 6:00 PM, Open all days",
      peakSeason: "During monsoon (July to October), timings may be restricted",
      viewingAreas: "Gaganachukki viewpoint: 8:00 AM to 5:30 PM",
      templeTimings: "Ranganathaswamy Temple: 7:00 AM to 1:00 PM and 4:00 PM to 8:00 PM"
    },
    weatherInfo: [
      { month: "Jan-Feb", temp: "22-28°C", description: "Pleasant" },
      { month: "Mar-May", temp: "30-35°C", description: "Hot" },
      { month: "Jun-Sep", temp: "23-28°C", description: "Monsoon" },
      { month: "Oct-Dec", temp: "20-25°C", description: "Cool" }
    ],
    thingsToDo: {
      couple: [
        "Romantic walk along the river banks",
        "Sunset viewing from Gaganachukki point",
        "Picnic near Bharachukki falls"
      ],
      kids: [
        "Safe splash areas near Bharachukki",
        "Bird watching",
        "Educational trip to the hydroelectric power station"
      ],
      family: [
        "Group photography sessions",
        "Visit to Ranganathaswamy Temple",
        "Nature trails in the surrounding forest area"
      ]
    },
    nearbySpots: [
      {
        name: "Talakadu",
        distance: "30 km",
        description: "Ancient town known for temples buried under sand"
      },
      {
        name: "Somnathpur Temple",
        distance: "80 km",
        description: "13th-century Hoysala temple with intricate carvings"
      },
      {
        name: "Ranganathittu Bird Sanctuary",
        distance: "60 km",
        description: "Home to numerous bird species, crocodiles"
      }
    ]
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ bgcolor: 'background.default', minHeight: '100vh' }}>
        <HeroSection>
          <AnimatedTypography variant="h1" fontWeight="bold" sx={{ zIndex: 1, mb: 2 }}>
            {spotData.name}
          </AnimatedTypography>
          <AnimatedTypography variant="h4" sx={{ zIndex: 1, mb: 3 }}>
            Nature's Majestic Wonder
          </AnimatedTypography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, zIndex: 1 }}>
            <InfoChip icon={<LocationOn />} label={spotData.location} />
            <InfoChip icon={<AttachMoney />} label={spotData.entryFee} />
            <InfoChip icon={<WbSunny />} label={spotData.weather} />
          </Box>
          <ScrollIndicator>
            <KeyboardArrowDown fontSize="large" />
          </ScrollIndicator>
        </HeroSection>

        <Container maxWidth="lg">
          <Section>
            <Typography variant="h2" gutterBottom>Quick Info</Typography>
            <Grid container spacing={3}>
              {[
                { icon: <Event />, title: "Best Time", content: spotData.bestTime },
                { icon: <ChildCare />, title: "Kids Friendly", content: spotData.kidsFriendly },
                { icon: <HourglassEmpty />, title: "Time to Spend", content: spotData.timeToSpend },
                { icon: <Attractions />, title: "Nearest Attraction", content: spotData.nearestAttraction }
              ].map((item, index) => (
                <Grid item xs={6} sm={3} key={index}>
                  <StyledCard>
                    <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                      <IconButton size="large" color="primary">
                        {item.icon}
                      </IconButton>
                      <Typography variant="h6" fontWeight="bold">
                        {item.title}
                      </Typography>
                      <Typography variant="body2">
                        {item.content}
                      </Typography>
                    </CardContent>
                  </StyledCard>
                </Grid>
              ))}
            </Grid>
          </Section>

          <Section>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <ImageGallery variant="quilted" cols={2} rowHeight={isMobile ? 150 : 200}>
                  {[...Array(4)].map((_, index) => (
                    <ImageListItem key={index} cols={1} rows={index === 0 ? 2 : 1}>
                      <img
                        src={`https://source.unsplash.com/random/400x300?waterfall&sig=${index}`}
                        alt={`Shivanasamudra ${index + 1}`}
                        loading="lazy"
                      />
                    </ImageListItem>
                  ))}
                </ImageGallery>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="h3" gutterBottom>About</Typography>
                <Typography variant="body1" paragraph>
                  {spotData.description}
                </Typography>
                <Button 
                  variant="outlined" 
                  onClick={() => setExpandedAccordion('about')}
                  endIcon={<ExpandMore />}
                >
                  Read More
                </Button>
                <Accordion 
                  expanded={expandedAccordion === 'about'} 
                  onChange={handleAccordionChange('about')}
                  sx={{ mt: 2, bgcolor: 'background.paper' }}
                >
                  <AccordionDetails>
                    <Typography variant="body1">
                      {spotData.fullDescription}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
          </Section>

          <Section>
            <Typography variant="h3" gutterBottom>How to Reach</Typography>
            <Tabs 
              value={activeTab} 
              onChange={handleTabChange} 
              variant="scrollable" 
              scrollButtons="auto"
              sx={{ mb: 3 }}
            >
              <Tab icon={<DirectionsBus />} label="Bus" />
              <Tab icon={<DirectionsCar />} label="Car" />
              <Tab icon={<Train />} label="Public Transport" />
              <Tab icon={<Flight />} label="Air" />
            </Tabs>
            <Paper elevation={3} sx={{ p: 3, bgcolor: 'background.paper' }}>
              {activeTab === 0 && <Typography>{spotData.howToReach.bus}</Typography>}
              {activeTab === 1 && <Typography>{spotData.howToReach.car}</Typography>}
              {activeTab === 2 && <Typography>{spotData.howToReach.publicTransport}</Typography>}
              {activeTab === 3 && <Typography>{spotData.howToReach.airport}</Typography>}
            </Paper>
          </Section>

          <Section>
            <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
                <Typography variant="h3" gutterBottom>Timings</Typography>
                {Object.entries(spotData.timings).map(([key, value]) => (
                  <Paper key={key} elevation={3} sx={{ p: 2, mb: 2, bgcolor: 'background.paper' }}>
                    <Typography variant="h6" color="primary">{key}</Typography>
                    <Typography variant="body1">{value}</Typography>
                  </Paper>
                ))}
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="h3" gutterBottom>Weather</Typography>
                <Grid container spacing={2}>
                  {spotData.weatherInfo.map((info, index) => (
                    <Grid item xs={6} key={index}>
                      <Paper elevation={3} sx={{ p: 2, height: '100%', bgcolor: 'background.paper' }}>
                        <Typography variant="h6" color="primary">{info.month}</Typography>
                        <Typography variant="body1">
                          <WbSunny fontSize="small" sx={{ verticalAlign: 'middle', mr: 1 }} />
                          {info.temp}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">{info.description}</Typography>
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Section>

          <Section>
            <Typography variant="h3" gutterBottom>Things to Do</Typography>
            <Grid container spacing={3}>
              {Object.entries(spotData.thingsToDo).map(([category, activities]) => (
                <Grid item xs={12} sm={4} key={category}>
                  <StyledCard>
                    <CardContent>
                      <Typography variant="h5" gutterBottom color="primary">
                        {category === 'couple' && <Favorite sx={{ mr: 1, verticalAlign: 'middle' }} />}
                        {category === 'kids' && <ChildCare sx={{ mr: 1, verticalAlign: 'middle' }} />}
                        {category === 'family' && <Groups sx={{ mr: 1, verticalAlign: 'middle' }} />}
                        For {category}
                      </Typography>
                      <List>
                        {activities.map((activity, index) => (
                          <ListItem key={index}>
                            <ListItemIcon>
                              <PhotoCamera color="primary" />
                            </ListItemIcon>
                            <ListItemText primary={activity} />
                          </ListItem>
                        ))}
                      </List>
                    </CardContent>
                  </StyledCard>
                </Grid>
              ))}
            </Grid>
          </Section>

          <Section>
            <Typography variant="h3" gutterBottom>Nearby Spots</Typography>
            <Box sx={{ overflowX: 'auto', pb: 2 }}>
              <Box sx={{ display: 'flex', gap: 2, width: isMobile ? 'max-content' : '100%' }}>
                {spotData.nearbySpots.map((spot, index) => (
                  <StyledCard key={index} sx={{ width: isMobile ? 280 : '30%', flexShrink: 0 }}>
                    <CardContent>
                      <Typography variant="h6" gutterBottom color="primary">{spot.name}</Typography>
                      <Typography variant="body2" color="text.secondary">Distance: {spot.distance}</Typography>
                      <Typography variant="body1" sx={{ mt: 1 }}>{spot.description}</Typography>
                    </CardContent>
                  </StyledCard>
                ))}
              </Box>
            </Box>
          </Section>

          <Section>
            <Typography variant="h3" gutterBottom>Photo Gallery</Typography>
            <ImageGallery variant="masonry" cols={isMobile ? 2 : 3} gap={8}>
              {[...Array(6)].map((_, index) => (
                <ImageListItem key={index}>
                  <img
                    src={`https://source.unsplash.com/random/400x300?waterfall&sig=${index + 10}`}
                    alt={`Shivanasamudra ${index + 1}`}
                    loading="lazy"
                  />
                </ImageListItem>
              ))}
            </ImageGallery>
          </Section>

          <Section>
            <Typography variant="h3" gutterBottom>Reviews</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
              <Rating value={4.5} readOnly precision={0.5} size="large" />
              <Typography variant="h4" sx={{ ml: 2 }}>4.5 out of 5</Typography>
            </Box>
            <Grid container spacing={3}>
              {[...Array(3)].map((_, index) => (
                <Grid item xs={12} md={4} key={index}>
                  <Paper elevation={3} sx={{ p: 2, height: '100%', bgcolor: 'background.paper' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                      <Avatar sx={{ mr: 2 }}>U{index + 1}</Avatar>
                      <Box>
                        <Typography variant="subtitle1">User {index + 1}</Typography>
                        <Rating value={5 - index} readOnly size="small" />
                      </Box>
                    </Box>
                    <Typography variant="body2">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </Typography>
                  </Paper>
                </Grid>
              ))}
            </Grid>
            <Box sx={{ textAlign: 'center', mt: 3 }}>
              <Button variant="contained" startIcon={<Star />}>
                Write a Review
              </Button>
            </Box>
          </Section>

          <Section>
            <Typography variant="h3" gutterBottom>Frequently Asked Questions</Typography>
            {[
              { question: "What is the best time to visit Shivanasamudra Falls?", answer: "The best time to visit is from October to February when the weather is pleasant and the water flow is still strong after the monsoon season." },
              { question: "Are there any nearby accommodations?", answer: "While there are limited options near the falls, you can find accommodations in nearby towns like Kollegal or Chamarajanagar. It's recommended to book in advance, especially during peak seasons." },
              { question: "Is it safe for swimming?", answer: "Swimming is not allowed at the main falls due to strong currents. However, there are some shallow areas near Bharachukki falls where visitors can enjoy water play under supervision during safer seasons." }
            ].map((faq, index) => (
              <Accordion key={index} sx={{ mb: 2, bgcolor: 'background.paper' }}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography variant="h6">{faq.question}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{faq.answer}</Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </Section>

          <Section sx={{ 
            backgroundImage: 'url(https://source.unsplash.com/random/1600x900?waterfall)', 
            backgroundSize: 'cover', 
            backgroundPosition: 'center',
            position: 'relative',
            '&::before': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
            }
          }}>
            <Box sx={{ position: 'relative', textAlign: 'center', color: 'white' }}>
              <Typography variant="h2" gutterBottom>Ready to Experience the Wonder?</Typography>
              <Button 
                variant="contained" 
                size="large" 
                sx={{ 
                  mt: 2, 
                  fontSize: '1.2rem', 
                  padding: '10px 30px',
                  backgroundColor: '#4CAF50',
                  '&:hover': {
                    backgroundColor: '#45a049',
                  }
                }}
              >
                Plan Your Visit Now
              </Button>
            </Box>
          </Section>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default ShivanasamudraPage;